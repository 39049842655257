import React from 'react'
import { Link } from 'gatsby'
import logo from '../images/joycards-logo.svg'
import bars from '../images/bars.svg'
import times from '../images/times.svg'

export default () => (

  <header>
    <div className='container-lg'>
      <div className='flex flex-ac flex-sb'>
        <Link to='/' className='flex flex-ac'>
          <img src={logo} className='logo' alt='Joycards logo' />
        </Link>
        <a href="#main-menu"
           role="button"
           id="main-menu-toggle"
           className="menu-toggle"
           aria-expanded="false"
           aria-controls="main-menu"
           aria-label="Open main menu">
          <span className="sr-only">Open main menu</span>
          <img src={bars} aria-hidden="true" alt="menu" />
        </a>

        <nav id="main-menu" role="navigation" className="main-menu" aria-expanded="false" aria-label="Main menu">
          <a href="#main-menu-toggle"
             role="button"
             id="main-menu-close"
             className="menu-close"
             aria-expanded="false"
             aria-controls="main-menu"
             aria-label="Close main menu">
            <span className="sr-only">Close main menu</span>
            <img src={times} aria-hidden="true" alt="click to close" />
          </a>
          <ul>
            <li><Link to='/faqs'>FAQs</Link></li>
            <li><a href="https://app.joy.cards/accounts/login/" target="_blank" rel="noopener noreferrer">Sign In</a></li>
            <li><a href="https://app.joy.cards/accounts/signup/" target="_blank" rel="noopener noreferrer">Sign Up</a></li>
          </ul>
        </nav>

        <a href="#main-menu-toggle"
           className="backdrop"
           tabIndex="-1"
           aria-hidden="true" hidden>
        </a>
      </div>
    </div>
  </header>

)
