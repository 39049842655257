import React from 'react'
import { Link } from 'gatsby'
import logo from '../images/joycards-logo.svg'

export default () => (

  <footer>
    <div className='container'>
      <div className='four-grid'>
        <Link to='/'>
          <img src={logo} alt='Joycards logo' />
        </Link>
        <ul className='list-unstyled'>
          <h6>Company</h6>
          <li>
            <Link to='/contact'>Contact</Link>
          </li>
          <li>
            <Link to='/terms'>Terms</Link>
          </li>
          <li>
            <Link to='/privacy'>Privacy Policy</Link>
          </li>
        </ul>
        <ul className='list-unstyled'>
          <h6>Product</h6>
          <li>
            <Link to='/birthday-video-maker'>Birthday video maker</Link>
          </li>
          <li>
            <Link to='/faqs'>FAQs</Link>
          </li>
          <li>
            <a href="https://app.joy.cards/accounts/signup/" target="_blank" rel="noopener noreferrer">Sign Up</a>
          </li>
          <li>
            <a href="https://app.joy.cards/accounts/login/" target="_blank" rel="noopener noreferrer">Sign In</a>
          </li>
        </ul>
      </div>
    </div>

    <div className='flex flex-jc beige-bg foot-sm'>
      <div>Save the <span role="img" aria-label="tree">🌳</span>  -  Made in <span role="img" aria-label="Australia flag">🇦🇺</span></div>
    </div>
  </footer>
)
